import { Sport } from '@prisma/client';
import {
  json,
  type LoaderFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
  type LinkDescriptor } from
'@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';

import styles from '#/app/styles/theme.css';

import { ConsentBanner } from './components/ConsentBanner.tsx';
import { GeneralErrorBoundary } from './components/ErrorBoundary.tsx';
import { metaTitleAndDescription } from './helpers/metaTags.helper.ts';
import { fetchUserWithFullProfile } from './models/user.server.ts';
import { authenticator, getUserId } from './utils/auth.server.ts';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { getEnv } from './utils/env.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { makeTimings, time } from './utils/timing.server.ts';

export const links: LinksFunction = () => {
  return [
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const, // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/png', href: '/favicons/favicon-32x32.png' },
  { rel: 'stylesheet', href: styles }] as
  LinkDescriptor[];
};

export const baseMeta = {
  title: 'Clube do Tennis',
  description: 'Onde o tenista dá match',
  content_type: 'website'
};

export const meta: MetaFunction<typeof loader> = () => {
  return metaTitleAndDescription(baseMeta);
};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(() => fetchUserWithFullProfile({ id: userId }, Sport.Tennis), {
    timings,
    type: 'find user',
    desc: 'find user in root'
  }) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await authenticator.logout(request, { redirectTo: '/' });
  }

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname
      },
      ENV: getEnv()
    },
    {
      headers: combineHeaders({ 'Server-Timing': timings.toString() })
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

function Document({
  children,
  nonce,
  env = {}




}: {children: React.ReactNode;nonce: string;env?: Record<string, string>;}) {
  return (
    <html lang="en">
      <head>
        <ClientHintCheck nonce={nonce} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta name="theme-color" content="#f8f8fb" />
        <Meta />
        <Links />
      </head>
      <body data-layout="horizontal">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />


        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
        {env.MODE === 'production' &&
        <>
            <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-X0HE3ZTYW1" />

            <script
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', 'G-X0HE3ZTYW1');
                    `
            }} />

          </>
        }
      </body>
    </html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();

  return (
    <Document nonce={nonce} env={data.ENV}>
      <div className="position-relative">
        <ConsentBanner />
        <Outlet />
      </div>
    </Document>);

}
export default withSentry(App);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>);

}