import { useRevalidator } from '@remix-run/react'
import { Button, Card, CardBody } from 'reactstrap'
import { clientHints, useHints } from '#app/utils/client-hints.tsx'

export function ConsentBanner() {
  const { revalidate } = useRevalidator()
  const hints = useHints()

  if (hints['consent'] === 'true') return null

  return (
    <div className="z-3 fixed-bottom mb-3 mx-3 mx-md-auto w-md-50 w-lg-25">
      <Card className="shadow-lg">
        <CardBody className="p-3">
          <p className="text-center fs-6">
            Utilizamos cookies para garantir que você obtenha a melhor
            experiência em nossa plataforma. Ao utilizar nosso serviço, você
            está de ciente e concorda com seu uso.
          </p>
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              className="w-100"
              onClick={() => {
                document.cookie = `${clientHints.consent.cookieName}=true`
                revalidate()
              }}
            >
              Entendi
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
